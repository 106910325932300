import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../../utils/animation';

const Intro = (props) => {
  const {
    color,
    className,
    children,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <P
          ref={ref}
          pose={inView ? 'visible' : 'hidden'}
          className={className}
          color={color}
        >
          {children}
        </P>
      )}
    </InView>
  );
};

const P = styled(posed.p(slideInBottom))`
  font-size: 0.8rem;
  max-width: 400px;

  @media (min-width: 530px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    margin-bottom: 3.5rem;
  }
`;

export default Intro;
