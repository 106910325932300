import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../utils/animation';

const H2 = (props) => {
  const {
    color,
    className,
    children,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Heading
          ref={ref}
          pose={inView ? 'visible' : 'hidden'}
          className={className}
          color={color}
        >
          {children}
        </Heading>
      )}
    </InView>
  );
};

const Heading = styled(posed.h2(slideInBottom))`
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.05;
  font-family: var(--font-family-headings);

  @media (min-width: 530px) {
    font-size: 2.25rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    margin-bottom: 3rem;
    font-size: 3rem;
  }
`;

export default H2;
