import React from 'react';
import styled from 'styled-components';
import TeaserImage from './teaser/teaser-image';
import TeaserCard from './teaser/teaser-card';

const Teaser = (props) => {
  const {
    teaser: {
      text,
      image01,
      image02,
      image03,
    },
    primaryColor,
    shadowColor,
  } = props;

  return (
    <Container>
      <Offset>
        <Overlay>
          <Img1
            src={image01}
            alt="SawDust Festival"
            primaryColor={primaryColor}
          />
          <Img2
            src={image02}
            alt="SawDust Festival"
            primaryColor={primaryColor}
          />
        </Overlay>
        <TeaserCard
          text={text}
          cta="Come to the festival to learn and experience even more!"
          primaryColor={primaryColor}
          shadowColor={shadowColor}
        />
      </Offset>
      <LastImage>
        <Img3
          src={image03}
          alt="SawDust Festival"
          primaryColor={primaryColor}
        />
      </LastImage>
    </Container>
  );
};

export default Teaser;

const Container = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;

  @media (min-width: 768px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

const Offset = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: minmax(0, 490px) 1fr;
    grid-gap: 2rem;
  }
`;

const Overlay = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-template-rows: repeat(4, auto);

  @media (min-width: 768px) {
    align-self: start;
    padding: 0;
    grid-column: 2;
    grid-row: 1;
  }
`;

const LastImage = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

const Img1 = styled(TeaserImage)`
  position: relative;
  grid-column: 1 / span 6;
  grid-row: 1 / span 3;
`;

const Img2 = styled(TeaserImage)`
  position: relative;
  grid-column: 6 / span 3;
  grid-row: 3 / span 2;
`;

const Img3 = styled(TeaserImage)`
  max-width: 100%;
  width: 280px;
  margin-top: -2rem;
  position: relative;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;
