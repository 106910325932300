import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import { pull, pull4, pull2, drag2 } from '../../utils/animation';

const IconLink = React.forwardRef((props, ref) => {
  const {
    url,
    text,
    color,
    className,
  } = props;

  return (
    <StyledIconLink
      href={url}
      className={className}
      color={color}
      target="_blank"
      rel="noopener"
      ref={ref}
    >
      <Icon>
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
          <g fill="none" fillRule="evenodd">
            <ShadowPath fill={color} d="M24.5 6a18.5 18.5 0 1 1 0 37 18.5 18.5 0 0 1 0-37zm0 2a16.5 16.5 0 1 0 0 33 16.5 16.5 0 0 0 0-33z" />
            <CirclePath fill="#000" d="M21.5 3a18.5 18.5 0 1 1 0 37 18.5 18.5 0 0 1 0-37zm0 2a16.5 16.5 0 1 0 0 33 16.5 16.5 0 0 0 0-33z" />
            <ArrowPath fill="#000" points="29.17 20.21 24.51 15.56 25.93 14.14 33 21.21 25.93 28.28 24.51 26.87 29.17 22.21 10 22.21 10 20.21" />
          </g>
        </svg>
      </Icon>
      <Label>{text}</Label>
    </StyledIconLink>
  );
});

const CirclePath = posed.path(pull2);
const ShadowPath = posed.path(drag2);
const ArrowPath = posed.polygon(pull4);

const StyledIconLink = styled(posed.a(pull))`
  display: flex;
  align-items: center;
  color: #000;
  transition: color 0.2s linear;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.color};
  }
`;

const Icon = styled.span`
  width: 43px;
  height: 43px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  color: #000;

  @media (min-width: 768px) {
    margin-right: 1rem;
  }
`;

const Label = styled.span`
  text-transform: uppercase;
  font-family: var(--font-family-medium);
`;

export default IconLink;
