import React from 'react';
import styled from 'styled-components';
import { Offset } from '../elements/layout';
import Meta from './meta';
import H1 from './h1';
import Bar from './content/bar';
import Article from './content/article';

const Content = (props) => {
  const {
    title,
    body,
    featuredArtist,
    primaryColor,
    shadowColor,
    secondaryColor,
    contrastColor,
  } = props;

  return (
    <Container>
      <Offset>
        <Wrap color={secondaryColor}>
          <Bar backgroundColor={primaryColor} textColor={contrastColor}>
            <span>Featured Artist: </span>
            <span>{featuredArtist}</span>
          </Bar>
          <ContentWrap>
            <Meta color={shadowColor}>Artists & Makers</Meta>
            <H1>{title}</H1>
            <Article body={body} />
          </ContentWrap>
        </Wrap>
      </Offset>
    </Container>
  );
};

const Container = styled.div`
  margin-top: -6.35rem;
  position: relative;

  @media (min-width: 768px) {
    margin-top: -8.5rem;
  }

  @media (min-width: 1140px) {
    margin-top: -11.5rem;
  }
`;

const Wrap = styled.div`
  padding-bottom: 10rem;
  background: ${({ color }) => color};
`;

const ContentWrap = styled.div`
  padding: 1.5rem 1rem;

  @media (min-width: 415px) {
    padding: 1.5rem;
  }

  @media (min-width: 530px) {
    padding: 2rem 3.75rem 3.75rem;
  }

  @media (min-width: 768px) {
    padding: 3.75rem 3.75rem 6.5rem;
  }
`;

export default Content;
