import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import Hero from '../components/hero';
import Content from '../components/content';
import Teaser from '../components/teaser';
import Artists from '../components/artists';
import CallToAction from '../components/call-to-action';
import Details from '../components/details';
import Banner from '../components/banner';
import FooterImage from '../components/footer-image';
import Footer from '../components/footer';
import Instagram from '../components/instagram';
import HeroSVGMask from '../components/hero-mask';
import BackgroundSection from '../components/background-section';
import Switcher from '../components/switcher';

const LandingPage = ({ data, className }) => {
  const {
    contentJson: {
      title,
      body,
      heroArtist,
      heroProduct,
      featuredArtist,
      primaryColor,
      shadowColor,
      secondaryColor,
      contrastColor,
      teaser,
      artistsTitle,
      artistsSubtitle,
      artists,
      footerImage,
    },
  } = data;

  return (
    <Layout className={className}>
      <SEO title={title} />
      <Header color={shadowColor} />
      <main>
        <BackgroundSection>
          <Hero
            heroArtist={heroArtist.childImageSharp.fluid}
            heroProduct={heroProduct.childImageSharp.fluid}
            title={title}
            featuredArtist={featuredArtist}
          />
        </BackgroundSection>
        <Content
          title={title}
          body={body}
          featuredArtist={featuredArtist}
          primaryColor={primaryColor}
          shadowColor={shadowColor}
          secondaryColor={secondaryColor}
          contrastColor={contrastColor}
        />
        <CallToAction primaryColor={primaryColor} />
        <Teaser
          teaser={teaser}
          primaryColor={primaryColor}
          shadowColor={shadowColor}
        />
        <Artists
          data={artists}
          title={artistsTitle}
          medium={title}
          subtitle={artistsSubtitle}
          primaryColor={primaryColor}
          shadowColor={shadowColor}
          secondaryColor={secondaryColor}
        />
        <CallToAction primaryColor={primaryColor} />
        <Details
          primaryColor={primaryColor}
          shadowColor={shadowColor}
          background="#fff"
        />
        <FooterImage
          fluid={footerImage.childImageSharp.fluid}
          alt={title}
        />
        <Instagram />
        <Banner />
        <CallToAction primaryColor={primaryColor} />
      </main>
      <Footer />
      <Switcher />
      <HeroSVGMask />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentJson( fields: {
      slug: {
        eq: $slug
      }
    }){
      title
      heroArtist {
        childImageSharp {
          fluid(maxWidth: 1344, srcSetBreakpoints: [ 320, 415, 568, 768, 1024, 1144 ]) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      heroProduct {
        childImageSharp {
          fluid(maxWidth: 1344, srcSetBreakpoints: [ 320, 415, 568, 768, 1024, 1144 ]) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      featuredArtist
      primaryColor
      shadowColor
      secondaryColor
      contrastColor
      teaser {
        text
        image01 {
          childImageSharp {
            fluid(maxWidth: 279) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image02 {
          childImageSharp {
            fluid(maxWidth: 140) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image03 {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      artistsTitle
      artistsSubtitle
      artists {
        childrenArtistsCsv {
          id
          fname
          lname
          phone
          email
          website
          avatar {
            childImageSharp {
              fluid(maxWidth: 244) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      footerImage {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default LandingPage;
