import React from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import { Offset } from '../elements/layout';
import Meta from './meta';
import H2 from './h2';
import ArtistCard from './artists/artist-card';
import Intro from './artists/intro';
import Blades from './artists/blades';

const Artists = (props) => {
  const {
    data: {
      childrenArtistsCsv: artists,
    },
    title,
    subtitle,
    medium,
    className,
    primaryColor,
    shadowColor,
    secondaryColor,
  } = props;

  const firstArtist = artists[0];

  const artistsWithAvatars = sortBy(artists.slice(1).filter((node) => {
    if (!node.avatar.childImageSharp.fluid.src.includes('avatar.jpg')) {
      return true;
    }
    return false;
  }), 'lname');

  const artistsWithoutAvatars = sortBy(artists.filter((node) => {
    if (node.avatar.childImageSharp.fluid.src.includes('avatar.jpg')) {
      return true;
    }
    return false;
  }), 'lname');

  const filteredArtists = [firstArtist, ...artistsWithAvatars, ...artistsWithoutAvatars];

  return (
    <Container className={className}>
      <Blades color={secondaryColor} />
      <Offset>
        <Meta color={shadowColor}>Two Hundred</Meta>
        <H2 as="h2">{title}</H2>
        <Intro>{`${subtitle} The artists featured below work with the ${medium} medium.`}</Intro>
        <Wrap color={primaryColor}>
          {filteredArtists.map((node) => (
            <Artist
              name={`${node.fname} ${node.lname}`}
              phone={node.phone}
              email={node.email}
              website={node.website}
              avatar={node.avatar}
              color={primaryColor}
              key={node.id}
            />
          ))}
        </Wrap>
      </Offset>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 5rem 0 10rem;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    padding: 8rem 0 12rem;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0.75rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ color }) => color};
  }

  @media (min-width: 1140px) {
    margin-right: calc((100vw - 100% - 5rem) / -2);
  }
`;

const Artist = styled(ArtistCard)`
  flex: 0 0 auto;
  margin-right: 1.5rem;
  width: 244px;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    width: 320px;
    margin-bottom: 2rem;
  }
`;

export default Artists;
