import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { fadeIn } from '../../utils/animation';

const ArtistCard = (props) => {
  const {
    name,
    phone,
    email,
    website,
    className,
    avatar,
    color,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container
          ref={ref}
          className={className}
          pose={inView ? 'visible' : 'hidden'}
          color={color}
        >
          <Img fluid={avatar.childImageSharp.fluid} alt={name} />
          <Wrap>
            <Name>{name}</Name>
            { phone.length > 0 && <Meta href={`tel://${phone}`}>{phone}</Meta> }
            { email.length > 0 && <Meta href={`mailto:${email}`} rel="noopener">{email}</Meta> }
            { website.length > 0 && <Meta href={`http://${website}/`} rel="noopener" target="_blank">{website}</Meta> }
          </Wrap>
        </Container>
      )}
    </InView>
  );
};

const Container = styled(posed.div(fadeIn))`
  background-color: #fff;
  position: relative;

  &:first-of-type::after {
    content: '';
    width: 100%;
    width: calc(100% + 2px);
    height: 100%;
    height: calc(100% + 2px);
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 0 3px ${props => props.color};
    transform: translate3d(5px, 5px, 0);
  }
`;

const Wrap = styled.div`
  padding: 1rem;
`;

const Name = styled.h3`
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.75rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const Meta = styled.a`
  display: block;
  font-size: 0.65rem;
  margin-bottom: 0;
  color: var(--color-dark-gray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: var(--color-dark-gray);
  }

  @media (min-width: 768px) {
    font-size: 0.8rem;
  }
`;

export default ArtistCard;
