import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom, pop } from '../../utils/animation';
import { QuestionIcon } from '../svg-sprite';

import Meta from '../meta';

const TeaserCard = (props) => {
  const {
    text,
    cta,
    primaryColor,
    shadowColor,
    className,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Card ref={ref} pose={inView ? 'visible' : 'hidden'} className={className}>
          <CardTitle color={shadowColor}>Did You Know?</CardTitle>
          <CardText>
            {text}
          </CardText>
          <CardCTA>{cta}</CardCTA>
          <StyledQuestionIconWrap>
            <StyledQuestionIcon color={primaryColor} />
          </StyledQuestionIconWrap>
        </Card>
      )}
    </InView>
  );
};

export default TeaserCard;

const Card = styled(posed.div(slideInBottom))`
  position: relative;
  z-index: 1;
  padding: 1.5rem 1.5rem 3rem;
  background: #f0f0f0;
  max-width: 450px;
  margin-top: 2rem;
  margin-left: 1rem;
  align-self: flex-end;

  @media (min-width: 768px) {
    padding: 2.5rem 2.5rem 3rem;
    margin-top: 5rem;
    grid-column: 1;
    grid-row: 1;
  }
`;

const CardTitle = styled.h3`
  font-size: 0.65rem;
  font-family: var(--font-family-base);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px ${(props) => props.color};

  @media (min-width: 768px) {
    font-size: 1rem;
    letter-spacing: 3px;
  }
`;

const CardText = styled.p`
  margin-top: 0;
  margin-bottom: var(--vr-mb);
  font-size: 0.8rem;
`;

const CardCTA = styled.p`
  max-width: 260px;
  margin-top: 1rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
  font-family: var(--font-family-headings);

  @media (min-width: 768px) {
    font-size: 0.9rem;
  }
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  width: 30px;
  height: 30px;

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledQuestionIconWrap = styled(posed.div(pop))`
  transform-origin: center;
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;

  @media (min-width: 768px) {
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
  }
`;
