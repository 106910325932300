import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';
import posed from 'react-pose';

import ArrowLink from './arrow-link';
import { slideInBottom, scale } from '../utils/animation';

const Instagram = (props) => {
  const {
    data,
    className,
  } = props;
  return (
    <Container className={className}>
      <Wrap>
        {data.allInstaNode.edges.map(({ node }) => (
          <InView key={node.id} triggerOnce>
            {({ inView, ref }) => (
              <PostWrap ref={ref} pose={inView ? 'visible' : 'hidden'}>
                <Post href={`https://instagram.com/p/${node.id}/`} aria-label={`Instagram post: ${node.caption}`}>
                  <StyledImg fluid={node.localFile.childImageSharp.fluid} />
                </Post>
              </PostWrap>
            )}
          </InView>
        ))}
        <InView triggerOnce>
          {({ inView, ref }) => (
            <ViewMore
              ref={ref} pose={inView ? 'visible' : 'hidden'}
              href="https://www.instagram.com/sawdustartfestival/"
              color="#000"
              text="View More"
              target="_blank"
            />
          )}
        </InView>
      </Wrap>
    </Container>
  );
};

const Container = styled.div`
  margin-top: -5%;
  margin-bottom: 4rem;
  padding: 0 1.5rem;

  @media (min-width: 530px) {
    padding: 0 3rem;
  }

  @media (min-width: 980px) {
    margin-bottom: 8rem;
  }
`;

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 900px;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: stretch;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Post = styled(posed.a(scale))`
  display: block;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  &:nth-of-type(5),
  &:nth-of-type(6) {
    display: none;
  }

  @media (min-width: 600px) {
    &:nth-of-type(5),
    &:nth-of-type(6) {
      display: block;
    }
  }
`;

const PostWrap = styled(posed.div(slideInBottom))`
  overflow: hidden;
  backface-visibility: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  > div {
    padding-bottom: 100% !important;
  }
`;

const ViewMore = styled(posed(ArrowLink)(slideInBottom))`
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  grid-column: span 2;

  @media (min-width: 600px) {
    margin-top: 1rem;
    grid-column: 2;
    justify-self: center;
    align-self: center;
  }
`;

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allInstaNode(sort: {fields: timestamp, order: DESC}, limit: 6) {
          edges {
            node {
              id
              timestamp
              caption
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Instagram data={data} {...props} />}
  />
);
