import React from 'react';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { slideInBottom } from '../utils/animation';

const FooterImage = (props) => {
  const {
    fluid,
    title,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container ref={ref} pose={inView ? 'visible' : 'hidden'}>
          <Img fluid={fluid} alt={title} />
        </Container>
      )}
    </InView>
  );
};

const Container = styled(posed.div(slideInBottom))`
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7));
  }
`;

export default FooterImage;
