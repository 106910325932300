import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../utils/animation';
import { Logo } from './svg-sprite';
import BackgroundSection from './background-section';

const Banner = (props) => {
  const {
    className,
  } = props;
  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container
          ref={ref}
          className={className}
          pose={inView ? 'visible' : 'hidden'}
        >
          <Wrap>
            <Logo />
          </Wrap>
        </Container>
      )}
    </InView>
  );
};

const Container = styled(posed.div(slideInBottom))`
  @media (min-width: 530px) {
    svg {
      width: 200px;
      height: 83px;
    }
  }

  @media (min-width: 1024px) {
    svg {
      width: 286px;
      height: 122px;
    }
  }
`;

const Wrap = styled(BackgroundSection)`
  height: 410px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 3rem;

  @media (min-width: 530px) {
    height: 500px;
    padding-top: 5rem;
  }

  @media (min-width: 1024px) {
    padding-top: 5rem;
    height: 550px;
  }

  @media (min-width: 1800px) {
    height: 730px;
  }
`;

export default Banner;
