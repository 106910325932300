import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../../utils/animation';

const TeaserImage = (props) => {
  const {
    src,
    alt,
    className,
    primaryColor,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <ImgWrap ref={ref} pose={inView ? 'visible' : 'hidden'} className={className}>
          <Img fluid={src.childImageSharp.fluid} alt={alt} />
          <Border primaryColor={primaryColor} />
        </ImgWrap>
      )}
    </InView>
  );
};

export default TeaserImage;

const ImgWrap = styled(posed.div(slideInBottom))`
  position: relative;
`;

const Border = styled.div`
  width: 100%;
  width: calc(100% + 2px);
  height: 100%;
  height: calc(100% + 2px);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 0 3px ${props => props.primaryColor};
  transform: translate3d(5px, 5px, 0);
`;
