import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../../utils/animation';

const Blades = (props) => {
  const {
    color,
    className,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container
          ref={ref}
          pose={inView ? 'visible' : 'hidden'}
          className={className}
          color={color}
        />
      )}
    </InView>
  );
};

const Container = styled(posed.div(slideInBottom))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='623' height='1508' viewBox='0 0 624 1508'><polygon fill='${({ color }) => color.replace('#', '%23')}' fill-rule='evenodd' points='528 1476 528 1508 480 1508 480 1398 432 1398 432 1468 384 1468 384 1433 336 1433 336 1412 288 1412 288 1437 240 1437 240 1460 192 1460 192 1482 144 1482 144 1398 96 1398 96 1350 48 1350 48 1435 0 1435 0 85 48 85 48 0 96 0 96 48 144 48 144 132 192 132 192 110 240 110 240 87 288 87 288 62 336 62 336 83 384 83 384 118 432 118 432 48 480 48 480 158 528 158 528 126 576 126 576 146 624 146 624 1496 576 1496 576 1476'/></svg>");
  background-repeat: repeat-x;
  background-size: contain;

  @media (min-width: 768px) {
    left: 2rem;
  }

  @media (min-width: 1140px) {
    left: 5%;
  }

  @media (min-width: 1440px) {
    left: 10%;
  }

  @media (min-width: 2560px) {
    left: 25%;
  }
`;

export default Blades;
