import React from 'react';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import styled from 'styled-components';
import { Offset } from '../elements/layout';
import Meta from './meta';
import H2 from './h2';
import { slideInBottom } from '../utils/animation';
import IconLink from './details/icon-link';


const Details = (props) => {
  const {
    primaryColor,
    shadowColor,
    background,
  } = props;

  const features = [
    { title: 'Open Daily', text: 'Friday, June 28 through Sunday, September 1, 2019 from 10am to 10pm' },
    { title: '200 Artists', text: 'Three stages of live music and entertainment. Art classes and daily demonstrations. Glassblowing. Hands-on pottery wheel. Craft beer and wine. Outdoor eateries and cafes.' },
    { title: 'Location', text: 'Located outdoors in in a cool three-acre eucalyptus grove in the heart of Laguna Beach, Sawdust Art Festival features a picturesque and eclectic hand-built village featuring the fine art and craft of over 200 local artists and makers.' },
  ];

  const links = [
    { label: 'Tickets', url: 'https://sawdustartfestival.org/tickets/' },
    { label: 'Festival Hours', url: 'https://sawdustartfestival.org/festival-hours/' },
    { label: 'Food & Refreshments', url: 'https://sawdustartfestival.org/food-refreshments/' },
    { label: 'Special Events', url: 'https://sawdustartfestival.org/special-events/' },
    { label: 'Maps & Directions', url: 'https://sawdustartfestival.org/about/map-and-directions/' },
    { label: 'Entertainment Schedule', url: 'https://sawdustartfestival.org/calendar/entertainment-schedule/' },
    { label: 'How to Exhibit', url: 'https://sawdustartfestival.org/artists/how-to-exhibit/' },
  ];

  return (
    <Container>
      <Offset>
        <Wrap>
          <Meta color={shadowColor}>53rd annual</Meta>
          <H2>Sawdust Art Festival</H2>
          <Content>
            <Features>
              {features.map((feature, index) => (
                <InView key={index} triggerOnce>
                  {({ inView, ref }) => (
                    <Feature ref={ref} pose={inView ? 'visible' : 'hidden'}>
                      <strong>{`${feature.title} —`}</strong>
                      <span>{feature.text}</span>
                    </Feature>
                  )}
                </InView>
              ))}
            </Features>
            <LinkList>
              {links.map((link, index) => (
                <InView key={index} triggerOnce>
                  {({ inView, ref }) => (
                    <LinkListItem ref={ref} pose={inView ? 'visible' : 'hidden'}>
                      <IconLink
                        color={primaryColor}
                        background={background}
                        text={link.label}
                        url={link.url}
                      />
                    </LinkListItem>
                  )}
                </InView>
              ))}
            </LinkList>
          </Content>
        </Wrap>
      </Offset>
    </Container>
  );
};

const Container = styled.div`
  margin: 3rem 0;

  @media (min-width: 768px) {
    margin: 5rem 0;
  }
`;

const Wrap = styled.div`
  padding-right: 1.5rem;
`;

const Content = styled.div`
  font-size: 0.8rem;

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
  }
`;

const Features = styled.ul`
  max-width: 400px;
  font-size: 0.8rem;
  margin-bottom: 2rem;
  margin-top: 0;
  list-style: none;
  padding: 0;
`;

const Feature = styled(posed.li(slideInBottom))`
  margin: 1rem 0 0;

  &:first-child {
    margin-top: 0;
  }

  strong {
    display: block;
    margin-bottom: 0.25rem;
    font-family: var(--font-family-medium);
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li + li {
    margin-top: 0.5rem;

    @media (min-width: 768px) {
      margin-top: 1rem;
    }
  }
`;

const LinkListItem = posed.li(slideInBottom);

export default Details;
