import React from 'react';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { slideInBottom, bladesTop, sloganTop } from '../utils/animation';

const Hero = (props) => {
  const {
    heroArtist,
    heroProduct,
    featuredArtist,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container>
          <Wrap>
            <ProductWrap ref={ref} pose={inView ? 'visible' : 'hidden'}>
              <Product
                fluid={heroProduct}
                alt={featuredArtist}
              />
            </ProductWrap>
            <ArtistWrap ref={ref} pose={inView ? 'visible' : 'hidden'}>
              <Artist
                fluid={heroArtist}
                alt={featuredArtist}
              />
            </ArtistWrap>
            <Slogan ref={ref} pose={inView ? 'visible' : 'hidden'}>
              Expect the unexpected
            </Slogan>
          </Wrap>
        </Container>
      )}
    </InView>
  );
};

const Container = styled.div`
  padding: 9rem 1rem 3rem 1.5rem;

  @media (min-width: 768px) {
    padding: 8rem 1.5rem 1.5rem 2.5rem;
  }
`;

const Wrap = styled.div`
  position: relative;
  max-width: 1344px;
  margin: 0 auto;
`;

const Product = styled(Img)`
  clip-path: url(#heroArtistMask);

  @media (max-width: 414px) {
    height: 320px;
    clip-path: url(#heroArtistMaskMobile);
  }
`;

const ProductWrap = styled(posed.div(slideInBottom))`
  opacity: 0;
`;

const Artist = styled(Img)`
  clip-path: url(#heroProductMask);

  @media (max-width: 414px) {
    height: 320px;
    clip-path: url(#heroProductMaskMobile);
  }
`;

const ArtistWrap = styled(posed.div(bladesTop))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Slogan = styled(posed.p(sloganTop))`
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
  color: #fff;
  position: absolute;
  bottom: 1rem;
  right: 100%;
  right: calc(100% + 2px);
  margin: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-family: var(--font-family-headings);

  @media (min-width: 768px) {
    bottom: 3.5rem;
    font-size: 1.5rem;
  }

  @media (min-width: 1024px) {
    bottom: 74px;
    font-size: 2rem;
  }

  @media (min-width: 1280px) {
    font-size: 2.5rem;
  }
`;

export default Hero;
